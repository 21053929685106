// @ts-nocheck
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TbWorldCode } from "react-icons/tb";
import {
  AiOutlineSync,
  AiOutlineWallet,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineGift,
} from "react-icons/ai";
import { FaArrowLeft } from "react-icons/fa";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

import { useLocation, useNavigate } from "react-router-dom";
import { formatMoney } from "../utils/phoneRegex";

import "../asset/css/main.css";
import PointLogo from "../asset/image/point-image.png";
import logo from "../asset/image/logo.png";

import { getProfileApi } from "../api/profileApi";
import { toastError } from "../utils/toast";
import { useTranslation } from "react-i18next";
import { useLanguageModal } from "../hooks/useLanguage";

function Navbar({ userData, isPrefix }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = !!userData?.token;
  const { t } = useTranslation();

  const { onOpen } = useLanguageModal();

  const [walletView, setWalletView] = useState(false);
  const [ploading, setPloading] = useState(false);
  const [point, setPoint] = useState(userData?.point || 0);
  const [amount, setAmount] = useState(userData?.balance || 0);
  const [turnover, setTurnover] = useState(userData?.turnover || 0);

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (!isLoggedIn) return;
      if (document.visibilityState === "visible") {
        await getProfileApi({ token: userData?.token })
          .then((res) => {
            if (res?.status === 200) {
              setPoint(res?.data?.point);
              setAmount(res?.data?.balance);
              setTurnover(res?.data?.turnover);
            }
          })
          .catch((err) => {
            throw err;
          });
      }
    };

    setAmount(userData?.balance || 0);
    setPoint(userData?.point || 0);

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [
    userData?.balance,
    userData?.token,
    userData?.point,
    userData?.p_balance,
    userData?.name,
    userData?.image,
    userData?.turnover,
    isLoggedIn,
  ]);

  const toggleSync = useCallback(async () => {
    if (!isLoggedIn || ploading) return;

    const promise = new Promise((resolve) => {
      setPloading(true);
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    promise.then(async () => {
      await getProfileApi({ token: userData?.token })
        .then((res) => {
          if (res?.status === 200) {
            setPoint(res?.data?.point);
            setAmount(res?.data?.balance);
            setTurnover(res?.data?.turnover);
          }
        })
        .catch(() => {
          return toastError("Something went wrong!");
        })
        .finally(() => setPloading(false));
    });
  }, [isLoggedIn, userData?.token, ploading]);

  const toggleViewWallet = useCallback(() => {
    setWalletView((prev) => !prev);
  }, []);

  const isShowWallet = useMemo(() => {
    if (!walletView) {
      return "xxxx";
    }
    return formatMoney(amount || 0);
  }, [amount, walletView]);

  return (
    <nav className="d-lg-none container">
      <section
        id="navbar"
        className={clsx(
          "d-flex justify-content-between align-items-center text-white",
          isPrefix && "py-2"
        )}
      >
        {!isPrefix ? (
          <>
            <div
              role="button"
              style={{ width: "3rem" }}
              onClick={() => navigate("/")}
            >
              <img alt="logo" className="w-100 rounded-3 py-1" src={logo} />
            </div>
          </>
        ) : (
          <div className="position-relative w-100 d-flex align-items-center justify-content-between mt-1">
            <FaArrowLeft
              size={25}
              role="button"
              onClick={() => navigate(-1)}
              className="position-absolute mb-1"
            />
            <div
              style={{ fontSize: "1.1rem" }}
              className="text-center w-100 fw-bold text-capitalize"
            >
              {t(
                location.pathname
                  .toLocaleLowerCase()
                  .substring(1, location.pathname.length)
              )}
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between align-content-center flex-row-reverse gap-3">
          {isLoggedIn && !isPrefix && (
            <AiOutlineSync
              role="button"
              title="Refresh"
              disabled={ploading}
              onClick={toggleSync}
              className={clsx(
                "cursor-pointer",
                ploading && "spinner-border border-0 text-white-50"
              )}
              size={25}
            />
          )}
          {!isPrefix && (
            <TbWorldCode
              size={25}
              role="button"
              onClick={onOpen}
              className="text-white"
            />
          )}
        </div>
      </section>

      {!isPrefix && (
        <>
          {isLoggedIn && (
            <section id="wallet" className="my-3 text-white">
              <div className="d-flex justify-content-between align-items-center gap-5">
                <div className="d-flex align-items-center justify-content-between gap-2 w-100">
                  <AiOutlineWallet size={25} />
                  <div className="fw-bold profile-amount">
                    {t("mainBalance")}
                  </div>
                  {walletView ? (
                    <AiOutlineEye
                      role="button"
                      onClick={toggleViewWallet}
                      size={22}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      role="button"
                      onClick={toggleViewWallet}
                      size={22}
                    />
                  )}
                  <div className="fw-bolder ms-1">
                    <span className="profile-wallet">{isShowWallet} </span>
                    <span style={{ fontSize: ".9rem" }}> 🪙</span>
                  </div>
                  <IoAddCircleOutline
                    role="button"
                    onClick={() => navigate("/cashin")}
                    size={25}
                  />
                </div>
              </div>

              <div className="profile-border my-2" />

              {turnover > 0 && (
                <div className="d-flex justify-content-between align-items-center gap-5 mb-1">
                  <div className="d-flex align-items-center justify-content-between gap-2 w-100">
                    <AiOutlineGift size={25} />
                    <div className="fw-bold profile-amount">
                      {t("turnover")}
                    </div>

                    <div className="fw-bolder ms-1">
                      <span className="profile-wallet">
                        {formatMoney(turnover)}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-between align-items-center gap-3">
                <div
                  role="button"
                  onClick={() => navigate("/point")}
                  className="profile-point"
                >
                  <img
                    title="Point value!"
                    src={PointLogo}
                    style={{ width: "25px" }}
                    alt="point-logo"
                  />

                  <div className="fw-bolder ms-1">
                    <span className="profile-amount">
                      {formatMoney(point || 0)}{" "}
                    </span>
                    <span
                      className="text-white-50"
                      style={{ fontSize: ".6rem" }}
                    >
                      Point{point > 0 ? "s" : ""}
                    </span>
                  </div>
                  <MdOutlineKeyboardArrowRight
                    className="text-white-50"
                    size={25}
                  />
                </div>

                <button
                  disabled={ploading}
                  onClick={() => {
                    navigate("/cashin-promotion");
                  }}
                  title="click to claim promotion award!"
                  className={clsx(
                    "btn btn-sm border-0 rounded-1 w-50 fw-bold text-white",
                    ploading && "disabled"
                  )}
                  style={{ backgroundColor: "var(--secondary-color)" }}
                >
                  <span
                    style={{
                      color: "#fff",
                      fontSize: "11.5px",
                      letterSpacing: ".7px",
                    }}
                  >
                    Cashin promotion
                  </span>
                </button>
              </div>
            </section>
          )}
        </>
      )}
    </nav>
  );
}

export default Navbar;
