// @ts-nocheck
import React, { useCallback, useContext, useMemo, useState } from "react";
// lib
import i18n from "i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// images
import pointIcon from "../../asset/image/point-image.png";
import { AiOutlineWallet } from "react-icons/ai";

import MMFlag from "../../asset/image/localization/mm.png";
import UKFlag from "../../asset/image/localization/uk.png";
import ChinaFlag from "../../asset/image/localization/china.png";
import ThaiFlag from "../../asset/image/localization/tai.png";
import icon from "../../../src/asset/image/logo.png";
import { BaseURL } from "../../api/apiUrl";
// utils
import { moneyFormatter } from "../../utils/moneyFormatter";
import { removeLocalStorageNoJson } from "../../utils/localStorage";
// config
import { CN, CURRENT_LANG, EN, MM, THAI } from "../../localization/langConfig";
//icons
import { UserCircleIcon } from "@heroicons/react/24/solid";

// context
import { LoginContext } from "../../store/context/LoginProvider";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import { getProfileApi } from "../../api/profileApi";
import { formatMoney } from "../../utils/phoneRegex";

const FlagImage = ({ flagImg, width }) => {
  return (
    <img style={{ width, objectFit: "contain" }} src={flagImg} alt="..." />
  );
};

const WebsiteHeader = ({ userData, handleLogout, loading }) => {
  let { t } = useTranslation();
  let { pathname } = useLocation();
  pathname = useMemo(() => {
    return pathname !== "/"
      ? pathname.substring(1, pathname.length).split("/")[1]
      : null;
  }, [pathname]);

  const navigate = useNavigate();
  const getLang = removeLocalStorageNoJson(CURRENT_LANG);
  const [language, setchangeLanguage] = useState(getLang ? getLang : MM);
  const [openLang, setOpenLang] = useState(false);
  const [openLottery, setOpenLottery] = useState(false);
  const [amount, setAmount] = useState(userData?.balance || 0);
  const [point, setPoint] = useState(userData?.point || 0);
  const [ploading, setPloading] = useState(false);
  const [turnover, setTurnover] = useState(userData?.turnover || 0);

  const [
    {
      gameManagement: { category },
    },
  ] = useContext(LoginContext);
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    setchangeLanguage(lang);
    setOpenLang(false);
  };
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        await getProfileApi({ token: userData?.token })
          .then((res) => {
            if (res?.status === 200) {
              setAmount(res?.data?.balance);
              setPoint(res?.data?.point);
              setTurnover(res?.data?.turnover);
            }
          })
          .catch((err) => {
            throw err;
          });
      } else {
        // The tab is hidden
      }
    };

    setAmount(userData?.balance || 0);
    setPoint(userData?.point || 0);
    setTurnover(userData?.turnover);

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [userData?.balance, userData?.token, userData?.point, userData?.turnover]);

  return (
    <>
      <div
        style={{ width: "98%" }}
        className="d-none d-lg-flex justify-content-between align-items-center mx-auto"
      >
        <div
          className="text-warning gap-3 d-flex align-items-center justify-content-between"
          style={{ flex: 1 }}
        >
          <Link
            to="/"
            className="d-flex text-decoration-none text-white align-items-center"
          >
            <img
              src={icon}
              alt="icon"
              className="p-2"
              style={{ width: "7rem" }}
            />
          </Link>

          {/* Lang Switch */}
          <div
            onClick={() => setOpenLang(!openLang)}
            className="d-flex cursor-pointer align-items-center position-relative"
          >
            {language === EN ? (
              <FlagImage width={30} flagImg={UKFlag} />
            ) : language === MM ? (
              <FlagImage width={30} flagImg={MMFlag} />
            ) : language === CN ? (
              <FlagImage width={30} flagImg={ChinaFlag} />
            ) : (
              <FlagImage width={30} flagImg={ThaiFlag} />
            )}
            {/* Expand Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#fff"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
            </svg>

            {/* Land Switch: DropDown  */}
            <div
              className={`position-absolute text-black ${
                openLang ? "d-flex" : "d-none"
              } flex-column gap-1 bg-white rounded-3 py-2 px-3`}
              style={{ top: 35, zIndex: 10, width: "120px" }}
            >
              <div
                className={`${
                  language !== MM ? "d-flex" : "d-none"
                } gap-2 align-items-center cursor-pointer`}
                onClick={() => changeLang(MM)}
              >
                <FlagImage width={25} flagImg={MMFlag} />
                <span>ဗမာစာ</span>
              </div>
              <div
                className={`${
                  language !== EN ? "d-flex" : "d-none"
                } gap-2 align-items-center cursor-pointer`}
                onClick={() => changeLang(EN)}
              >
                <FlagImage width={25} flagImg={UKFlag} />
                <span>English</span>
              </div>
              <div
                className={`${
                  language !== THAI ? "d-flex" : "d-none"
                } gap-2 align-items-center cursor-pointer`}
                onClick={() => changeLang(THAI)}
              >
                <FlagImage width={25} flagImg={ThaiFlag} />
                <span>ไทย</span>
              </div>
              <div
                className={`${
                  language !== CN ? "d-flex" : "d-none"
                } gap-2 align-items-center cursor-pointer`}
                onClick={() => changeLang(CN)}
              >
                <FlagImage width={25} flagImg={ChinaFlag} />
                <span>中国人</span>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-black bg-opacity-50 rounded-5 py-1 ms-auto">
          {userData?.token ? (
            <div className="w-100 d-flex flex-row-reverse align-items-center gap-3">
              <div className="dropdown" style={{ cursor: "pointer" }}>
                <UserCircleIcon
                  style={{ color: "#0155A3" }}
                  width={30}
                  className="bg-white rounded-circle"
                  data-bs-toggle="dropdown"
                />
                <ul style={{ zIndex: "10000" }} className="dropdown-menu mt-1">
                  <Link to="/profile" className="text-decoration-none ">
                    <li className="profileBtn ps-3 py-1">{t("profile")}</li>
                  </Link>

                  <Link to="/wallet" className="text-decoration-none">
                    <li className="profileBtn ps-3 py-1">{t("wallet")}</li>
                  </Link>

                  <Link to="/betslip" className="text-decoration-none">
                    <li className="profileBtn ps-3 py-1">{t("betslip")}</li>
                  </Link>

                  <Link
                    to=""
                    onClick={handleLogout}
                    className="text-decoration-none"
                  >
                    <li className="profileBtn ps-3 py-1">{t("logout")}</li>
                  </Link>
                </ul>
              </div>

              {turnover > 0 && (
                <button
                  disabled
                  style={{ backgroundColor: "#0155A3" }}
                  className={`${
                    ploading && "btn-secondary"
                  } btn btn-sm text-white border-0`}
                >
                  Turnover {formatMoney(turnover)}
                </button>
              )}

              <div
                style={{ backgroundColor: "#0155A3" }}
                className="rounded-1 p-1 text-center d-flex flex-wrap justify-content-center align-items-center"
              >
                {/* Main Balance */}
                <AiOutlineWallet
                  className="text-warning"
                  style={{ marginBottom: "1px" }}
                  size={20}
                />
                <span className="px-1 fst-italic fw-bolder fs-6 font-monospace px-md-3 text-white">
                  {moneyFormatter(parseInt(amount))}{" "}
                  <span
                    style={{
                      fontSize: ".9rem",
                    }}
                  >
                    💰
                  </span>
                </span>
              </div>

              {/** cashin promotion */}
              <Link to="/cashin-promotion" className="point_div">
                <button className="btn btn-primary btn-sm rounded-2 fst-italic">
                  Cashin Promotion
                </button>
              </Link>

              {/** Point */}
              <Link to="/point" className="point_div">
                <img
                  src={pointIcon}
                  style={{ width: "2.3rem" }}
                  alt="point"
                  className="point_image"
                />
                <small className="text-nowrap py-1 point_boder ms-1">
                  {Math.floor(point)} Pts
                </small>
              </Link>
            </div>
          ) : (
            <div className="w-100 d-flex gap-1 ps-3">
              <Link
                to="/login"
                className="text-decoration-none d-flex align-items-center justify-content-end"
              >
                <button className="rounded-2 btn btn-danger border-2 px-3 me-2">
                  <span className="fw-bolder">{t("login")}</span>
                </button>
              </Link>

              <Link
                to="/register"
                className="text-decoration-none d-flex align-items-center justify-content-end"
              >
                <button className="rounded-2 btn btn-outline-secondary border-2 px-3 me-2">
                  <span className="fw-bolder">
                    {t("registerPage.register")}
                  </span>
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>

      {/**  Category Bar  */}
      <div className="d-none d-lg-flex text-center overflow-auto justify-content-center gap-4 align-items-center mt-0">
        <div className="text-warning gap-3 d-inline-flex">
          {category && !loading ? (
            category.length ? (
              category.map((cat, idx) => (
                <div
                  key={idx}
                  onClick={() => {
                    navigate(`${cat.name}`);
                    setOpenLottery(false);
                  }}
                  className={`d-flex align-items-center categoryHover px-3 py-0 shadow ${
                    pathname?.split("%20").join("") ===
                    cat.name.split(" ").join("")
                      ? "border-0 border-warning border-bottom border-2"
                      : "text-white-50 border-opacity-50 shadow-sm"
                  }  cursor-pointer`}
                >
                  <LazyLoadImage
                    style={{ width: "30px", objectFit: "contain" }}
                    effect="blur"
                    src={`${BaseURL}/${cat.image}`}
                  />
                  <div className="small ms-2 fw-bolder">{cat.name}</div>
                </div>
              ))
            ) : (
              <div>no category</div>
            )
          ) : (
            Array.from({ length: 5 }).map((a, idx) => (
              <div
                key={idx}
                style={{ minWidth: "10rem" }}
                className="d-flex gap-2 align-items-center px-2 py-2 h-50 shadow categoryHover cursor-pointer bg-dark bg-opacity-50 rounded-2"
              >
                <div
                  style={{ width: "25px", height: "25px" }}
                  className="bg-secondary pulse bg-opacity-50 rounded-circle p-2"
                ></div>
                <div
                  style={{ width: "80%", height: "23px" }}
                  className="bg-secondary pulse bg-opacity-50 rounded-1 p-2"
                ></div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default WebsiteHeader;
