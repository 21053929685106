// @ts-nocheck
import React, { useCallback } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import FooterLink from "../components/shared_comp/FooterLink";

import { BiHome } from "react-icons/bi";
import { HiOutlineWallet } from "react-icons/hi2";
import { RiCustomerServiceLine } from "react-icons/ri";
import { AiOutlineSetting } from "react-icons/ai";

import { activeUrl } from "../constants/prefixRoutes";

function Footer() {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const handleUrl = useCallback(
    (url) => {
      navigate(url);
    },
    [navigate]
  );

  const isActive = useCallback(
    (url) => {
      if (!pathname || !url) return false;
      return activeUrl.includes(pathname) && pathname === url;
    },
    [pathname]
  );

  return (
    <div className="footer-new text-white d-lg-none">
      <FooterLink
        label="home"
        icon={BiHome}
        isActive={isActive(activeUrl[0])}
        handleUrl={(url) => handleUrl(activeUrl[0])}
      />

      <FooterLink
        label="wallet"
        icon={HiOutlineWallet}
        isActive={isActive(activeUrl[1])}
        handleUrl={(url) => handleUrl(activeUrl[1])}
      />

      <FooterLink
        label="service"
        icon={RiCustomerServiceLine}
        isActive={isActive(activeUrl[2])}
        handleUrl={(url) => handleUrl(activeUrl[2])}
      />

      <FooterLink
        label="setting"
        icon={AiOutlineSetting}
        isActive={isActive(activeUrl[3])}
        handleUrl={(url) => handleUrl(activeUrl[3])}
      />
    </div>
  );
}

export default Footer;
